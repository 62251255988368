import {
    AddDocumentParamsEnum,
    AddDocumentParamsRequiredDocumentTypeEnum,
    DeliveryAddressDtoGenderEnum,
    DeliveryInformationDtoDeliveryServiceTypeEnum,
    GenerateDocumentParamsEnum,
    GenerateDocumentParamsRequiredDocumentTypeEnum,
    GetDocumentParamsEnum,
    GetDocumentParamsRequiredDocumentTypeEnum,
    HolderDtoEconomicSectorEnum,
    HolderDtoGenderEnum,
    ImportHalterDtoBerufsgruppeEnum,
    ListAvailableDocumentsEnum,
    RegistrationDtoServiceTypeEnum,
    RegistrationDtoUsageTypeEnum,
    RegistrationDtoVehicleTypeEnum,
    RegistrationListItemDtoOrderStatusEnum,
    RegistrationListItemDtoOrderTypeEnum,
    RegistrationOfficePortalAvailabilityDtoStatusEnum,
    RegistrationStatusDtoStatusEnum,
    RemoveDocumentParamsEnum,
    RemoveDocumentParamsRequiredDocumentTypeEnum,
    SendDocumentToHolderParamsEnum,
    SendDocumentToHolderParamsRequiredDocumentTypeEnum,
    SetUserOrganizationGroupEnum,
    UserOrganizationGroupDtoOrganizationRoleEnum
} from "./Api";

const deliveryInformationDeliveryServiceTypes = new Map<DeliveryInformationDtoDeliveryServiceTypeEnum, string>()

deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.VEHICLE_OWNER, 'Halter des Fahrzeugs')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.AUTHORIZED_REPRESENTATIVE, 'Bevollmächtigter')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.KFZ_REGISTRATION_SERVICE, 'ZLD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP, 'Abholung')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_VEHICLE_OWNER, 'Abholung durch Halter des Fahrzeugs')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE, 'Abholung durch Filiale/ZLD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_AUTHORIZED_REPRESENTATIVE, 'Abholung durch Bevollmächtigten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_THIRD, 'Abholung durch Dritten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING, 'Zusendung')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER, 'Zusendung an Halter des Fahrzeugs')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_REGISTRATION_SERVICE, 'Zusendung an ZLD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_AUTHORIZED_REPRESENTATIVE, 'Zustellung an Bevollmächtigten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD, 'Zusendung an einen Dritten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_USER_ACCOUNT, 'SENDING_TO_USER_ACCOUNT')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_SABRINA, 'SENDING_TO_SABRINA')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.DHL, 'DHL')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.DPD, 'DPD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.GLS, 'GLS')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.FEDEX, 'FEDEX')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.GO, 'GO')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.HERMES, 'HERMES')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.TNT, 'TNT')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.TRANS_O_FLEX, 'TRANS O FLEX')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.UPS, 'UPS')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.OTHER, 'OTHER')

function deliveryInformationDeliveryServiceType(val: DeliveryInformationDtoDeliveryServiceTypeEnum) {
    return deliveryInformationDeliveryServiceTypes.get(val) || val
}


const deliveryAddressGenders = new Map<DeliveryAddressDtoGenderEnum, string>()
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.MALE, 'Herr')
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.FEMALE, 'Frau')
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.DIVERSE, 'Divers')
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.NOT_PROVIDED, 'Nicht angegeben')

function deliveryAddressGender(val: DeliveryAddressDtoGenderEnum | HolderDtoGenderEnum) {
    return deliveryAddressGenders.get(val as any as DeliveryAddressDtoGenderEnum) || val
}

function orderType(orderType: RegistrationListItemDtoOrderTypeEnum | RegistrationDtoServiceTypeEnum): string {
    switch (orderType) {
        case RegistrationListItemDtoOrderTypeEnum.AB:
            return "Außerbetriebsetzung"
        case RegistrationListItemDtoOrderTypeEnum.NZ:
            return "Neuzulassung"
        case RegistrationListItemDtoOrderTypeEnum.TZ:
            return "Tageszulassung"
        case RegistrationListItemDtoOrderTypeEnum.UI:
            return "Umschreibung Innerhalb"
        case RegistrationListItemDtoOrderTypeEnum.UM:
            return "Umschreibung von Außerhalb mit Halterwechsel"
        case RegistrationListItemDtoOrderTypeEnum.UO:
            return "Umschreibung von Außerhalb ohne Halterwechsel"
        case RegistrationListItemDtoOrderTypeEnum.WZ:
            return "Wiederzulassung"
        case RegistrationListItemDtoOrderTypeEnum.HA:
            return "Halteränderung"
        default:
            return orderType
    }
}

function documentType(
    type: AddDocumentParamsEnum
        | GenerateDocumentParamsRequiredDocumentTypeEnum
        | GenerateDocumentParamsEnum
        | SendDocumentToHolderParamsRequiredDocumentTypeEnum
        | SendDocumentToHolderParamsEnum
        | RemoveDocumentParamsRequiredDocumentTypeEnum
        | RemoveDocumentParamsEnum
        | GetDocumentParamsRequiredDocumentTypeEnum
        | GetDocumentParamsEnum
        | AddDocumentParamsRequiredDocumentTypeEnum
        | ListAvailableDocumentsEnum
): string {
    switch (type) {
        case AddDocumentParamsEnum.FEE_RECEIPT:
            return 'Gebührenbescheid';
        case AddDocumentParamsEnum.REGISTRATION_RECEIPT:
            return 'Zulassungsbescheid';
        case AddDocumentParamsEnum.PRELIMINARY_REGISTRATION_PROOF:
            return 'Vorläufiger Zulassungsnachweis';
        case AddDocumentParamsEnum.DEREGISTRATION_RECEIPT:
            return 'Abmeldebescheinigung';
        case AddDocumentParamsEnum.SEPA:
            return 'SEPA Mandat';
        case AddDocumentParamsEnum.POA:
            return 'Vollmacht';
        case AddDocumentParamsEnum.DATA_CONSENT:
            return 'Einwilligung zur Datenverarbeitung';
        case AddDocumentParamsEnum.REJECTION_RECEIPT:
            return 'Ablehnungsbescheid'
        default:
            return '';
    }
}

function documentFileName(
    type: AddDocumentParamsEnum
        | GenerateDocumentParamsRequiredDocumentTypeEnum
        | GenerateDocumentParamsEnum
        | SendDocumentToHolderParamsRequiredDocumentTypeEnum
        | SendDocumentToHolderParamsEnum
        | RemoveDocumentParamsRequiredDocumentTypeEnum
        | RemoveDocumentParamsEnum
        | GetDocumentParamsRequiredDocumentTypeEnum
        | GetDocumentParamsEnum
        | AddDocumentParamsRequiredDocumentTypeEnum
        | ListAvailableDocumentsEnum
): string {
    switch (type) {
        case AddDocumentParamsEnum.FEE_RECEIPT:
            return 'Gebührenbescheid.pdf';
        case AddDocumentParamsEnum.REGISTRATION_RECEIPT:
            return 'Zulassungsbescheid.pdf';
        case AddDocumentParamsEnum.PRELIMINARY_REGISTRATION_PROOF:
            return 'VorlaeufigerZulassungsnachweis.pdf';
        case AddDocumentParamsEnum.DEREGISTRATION_RECEIPT:
            return 'Abmeldebescheinigung.pdf';
        case AddDocumentParamsEnum.SEPA:
            return 'SEPA-Mandat.pdf';
        case AddDocumentParamsEnum.POA:
            return 'Vollmacht.pdf';
        case AddDocumentParamsEnum.DATA_CONSENT:
            return 'Daten_Einwilligungserklärung.pdf';
        case AddDocumentParamsEnum.REJECTION_RECEIPT:
            return 'Ablehnungsbescheid.pdf';
        default:
            return '';
    }
}

function orderStatus(orderStatus: RegistrationListItemDtoOrderStatusEnum | RegistrationStatusDtoStatusEnum): string {
    switch (orderStatus) {
        case RegistrationListItemDtoOrderStatusEnum.CREATED:
            return "Zulassungsauftrag angelegt"
        case RegistrationListItemDtoOrderStatusEnum.FINISHED:
            return "Fertig"
        case RegistrationListItemDtoOrderStatusEnum.FAILED:
            return "Zulassungsauftrag fehlgeschlagen"
        case RegistrationListItemDtoOrderStatusEnum.SUBMITTED:
            return "Zulassung beantragt"
        case RegistrationListItemDtoOrderStatusEnum.SUBMISSION_FAILED:
            return "Zulassung konnte nicht beantragt werden"
        case RegistrationListItemDtoOrderStatusEnum.SUCCESS:
            return "Zugelassen"
        default:
            return orderStatus
    }
}

function economicSectors(economicSector: HolderDtoEconomicSectorEnum | ImportHalterDtoBerufsgruppeEnum) {
    switch (economicSector) {
        case HolderDtoEconomicSectorEnum.A00:
            return 'Landwirtschaft, Jagd und damit verbundene Tätigkeiten, Forstwirtschaft und Holzeinschlag, Fischerei und Aquakultur'
        case HolderDtoEconomicSectorEnum.B00:
            return 'Gewinnung von Erdöl und Erdgas, Kohlen- und Erzbergbau, Gewinnung von Steinen und Erden, sonstiger Bergbau, Erbringung von Dienstleistungen für den Bergbau und für die Gewinnung von Steinen und Erden'
        case HolderDtoEconomicSectorEnum.C01:
            return 'Herstellung/Verarbeitung von Nahrungsmitteln und Getränken - inkl. Obst- und Gemüseverarbeitung'
        case HolderDtoEconomicSectorEnum.C02:
            return 'Kokerei und Mineralölverarbeitung'
        case HolderDtoEconomicSectorEnum.C03:
            return 'Herstellung/Verarbeitung von chemischen und pharmazeutischen Erzeugnissen'
        case HolderDtoEconomicSectorEnum.C04:
            return 'Herstellung von Kraftwagen und -teilen, Sonstiger Fahrzeugbau für den Straßenverkehr'
        case HolderDtoEconomicSectorEnum.C05:
            return 'Maschinenbau, Metallerzeugung und -bearbeitung, Herstellung von Metallerzeugnissen, Herstellung von elektrischen Ausrüstungen, Reparatur von Maschinen und Ausrüstungen'
        case HolderDtoEconomicSectorEnum.C99:
            return 'Futtermittel, Tabak, Textilien, Bekleidung, Holz-, Kork-, Flecht- und Korbwaren, Gummi- und Kunststoffwaren, Glaswaren, Verarbeitung von Steinen und Erden, Baumaterialien, Möbel, Papier, Pappe etc. und Waren daraus, Druckerzeugnisse sowie Vervielfältigung von bespielten Ton-, Bild- und Datenträgern, Herstellung von Datenverarbeitungsgeräten, elektronischen und optischen Erzeugnissen, Herstellung von sonstigen Waren, Sonstiger Fahrzeugbau'
        case HolderDtoEconomicSectorEnum.D00:
            return 'Elektrizitätsversorgung inkl. -erzeugung, -übertragung, -verteilung und -handel, Gasversorgung inkl. -erzeugung, -verteilung und -handel, Wärme- und Kälteversorgung'
        case HolderDtoEconomicSectorEnum.E00:
            return 'Wasser- und Abwasserversorgung inkl. -beseitigung, Sammlung, Behandlung und Beseitigung von Abfällen, Rückgewinnung (Recycling), Beseitigung von Umweltverschmutzungen und sonstige Entsorgung'
        case HolderDtoEconomicSectorEnum.F00:
            return 'Hoch- und Tiefbau, vorbereitende Baustellenarbeiten, Bauinstallation, sonstiges Ausbaugewerbe'
        case HolderDtoEconomicSectorEnum.G01:
            return 'Handel mit Kraftfahrzeugen, Kraftwagenteilen und -zubehör'
        case HolderDtoEconomicSectorEnum.G02:
            return 'Handel mit Nahrungs- und Genussmitteln und Getränken, mit landwirtschaftlichen Grundstoffen und lebenden Tieren'
        case HolderDtoEconomicSectorEnum.G03:
            return 'Handel mit festen Brennstoffen und Mineralölerzeugnissen, mit Metall- und Kunststoffwaren für Bauzwecke sowie Installationsbedarf für Gas, Wasser und Heizung, mit Motorenkraftstoffen'
        case HolderDtoEconomicSectorEnum.G99:
            return 'Handel mit Gebrauchs- und Verbrauchsgütern, mit sonstigen Genussmitteln, mit Geräten der Informations- und Kommunikationstechnik, mit sonstigen Maschinen, Ausrüstungen und Zubehör, mit Verlagsprodukten, Sportausrüstungen und Spielwaren, Groß- und/oder Einzelhandel ohne ausgeprägten Schwerpunkt, Handelsvermittlung, Handel auf Märkten an Verkaufsständen; Versand- und Internet-Einzelhandel, Groß- und/oder Einzelhandel mit sonstigen Fahrzeugen'
        case HolderDtoEconomicSectorEnum.H01:
            return 'Güterbeförderung im Straßenverkehr; Sonstige Post-, Kurier- und Expressdienste'
        case HolderDtoEconomicSectorEnum.H02:
            return 'Personenbeförderung im Straßenverkehr und sonstigem Landverkehr'
        case HolderDtoEconomicSectorEnum.H03:
            return 'Güter- und Personenbeförderung im Eisenbahnverkehr, in der Schiff- und Luftfahrt inkl. See- und Flughafenbetriebe'
        case HolderDtoEconomicSectorEnum.H04:
            return 'Speditionen und Lagerei; Erbringung von anderen Dienstleistungen für den Verkehr'
        case HolderDtoEconomicSectorEnum.H05:
            return 'Arzneimitteltransporte'
        case HolderDtoEconomicSectorEnum.H06:
            return 'Deutsche Bahn AG'
        case HolderDtoEconomicSectorEnum.H07:
            return 'Deutsche Post AG'
        case HolderDtoEconomicSectorEnum.I00:
            return 'Gastgewerbe/Beherbergung und Gastronomie'
        case HolderDtoEconomicSectorEnum.J10:
            return 'Verlagswesen, Herstellung, Verleih und Vertrieb von Filmen und Fernsehprogrammen, Kinos, Tonstudios und Verlegen von Musik, Rundfunkanstalten'
        case HolderDtoEconomicSectorEnum.K11:
            return 'Telekommunikation, leitungsgebundene und drahtlose, Erbringung von Dienstleistungen der Informationstechnologie (IT), Informationsdienstleistungen, Erbringung von sonstigen Dienstleistungen für die Informationstechnologie'
        case HolderDtoEconomicSectorEnum.K12:
            return 'Deutsche Telekom AG'
        case HolderDtoEconomicSectorEnum.L10:
            return 'Erbringung von Finanz- und Versicherungsdienstleistungen, mit Finanz- und Versicherungsdienstleistungen verbundene Tätigkeiten'
        case HolderDtoEconomicSectorEnum.M10:
            return 'Grundstücks- und Wohnungswesen'
        case HolderDtoEconomicSectorEnum.N11:
            return 'Rechts- und Steuerberatung, Wirtschaftsprüfung, Verwaltung/Führung von Unternehmen und Betrieben, Unternehmensberatung, Architektur- und Ingenieurbüros, technische, physikalische und chemische Untersuchung, Forschung und Entwicklung, Werbung und Marktforschung, Sonst. freiberufliche, wissenschaftliche und technische Tätigkeiten'
        case HolderDtoEconomicSectorEnum.N12:
            return 'Tierärzte'
        case HolderDtoEconomicSectorEnum.O11:
            return 'Vermietung von Gebrauchsgütern, Leasing von nichtfinanziellen immateriellen Vermögensgegenständen, Vermittlung/Überlassung von Arbeitskräften, Personalmanagement, Reisebüros/-veranstalter und Erbringung sonstiger Reservierungsdienstleistungen, Wach- und Sicherheitsdienste sowie Detekteien, Facility Management (Gebäudebetreuung und -reinigung), Garten- und Landschaftsbau, Erbringung sonstiger wirtschaftl. Dienstleistungen für Unternehmen und Privatpersonen - soweit nicht aufgeführt'
        case HolderDtoEconomicSectorEnum.O12:
            return 'Vermietung von Kraftfahrzeugen ohne Gestellung eines Fahrers'
        case HolderDtoEconomicSectorEnum.O13:
            return 'Carsharing'
        case HolderDtoEconomicSectorEnum.P11:
            return 'Sonstige Öffentliche Verwaltung, Verteidigung, auswärtige Angelegenheiten; Sozialversicherung'
        case HolderDtoEconomicSectorEnum.P12:
            return 'Behörden des Bundes, der Länder, der Gemeinden und der Gemeindeverbände, Polizeien des Bundes, der Länder und der Gemeinden (einschl. Bereitschaftspolizeien) sowie der Verfassungsbehörden des Bundes und der Länder, Zivil- und Katastrophenschutz, Feuerwehren'
        case HolderDtoEconomicSectorEnum.Q10:
            return 'Erziehung und Unterricht, Erbringung von Dienstleistungen für den Unterricht'
        case HolderDtoEconomicSectorEnum.R10:
            return 'Krankenhäuser, Ärzte, Hebammen Hilfsorganisationen 1), Pflegeheime, stat. Einrichtungen zur psychosozialen Betreuung, Suchtbekämpfung, Alten- und Behindertenwohnheime, soziale Betreuung älterer und behinderter Menschen, sonstige Heime, sonstiges Gesundheits- und Sozialwesen inkl. Tagesbetreuung von Kindern'
        case HolderDtoEconomicSectorEnum.S10:
            return 'Kunst, Sport und Erholung'
        case HolderDtoEconomicSectorEnum.T11:
            return 'Interessenvertretungen sowie kirchliche und sonstige religiöse Vereinigungen, politische Parteien, Reparatur und Instandhaltung von DV-Geräten und Gebrauchsgütern, Erbringung von sonstigen überwiegend persönlichen Dienstleistungen'
        case HolderDtoEconomicSectorEnum.T12:
            return 'Instandhaltung und Reparatur von Kraftfahrzeugen'
        case HolderDtoEconomicSectorEnum.V10:
            return 'Exterritoriale Organisationen und Körperschaften'
        default:
            return economicSector
    }
}

function vehicleType(type: RegistrationDtoVehicleTypeEnum): string {
    switch (type) {
        case RegistrationDtoVehicleTypeEnum.CAR: return 'PKW/LKW'
        case RegistrationDtoVehicleTypeEnum.MOTORCYCLE: return 'Motorrad'
        case RegistrationDtoVehicleTypeEnum.TRAILER: return 'Anhänger'
        default: return type
    }
}

function vehicleUsageType(type: RegistrationDtoUsageTypeEnum): string {
    switch (type) {
        case RegistrationDtoUsageTypeEnum.B:
            return 'Kfz. für behinderte Menschen, Schüler, Kindergartenträger'
        case RegistrationDtoUsageTypeEnum.C:
            return 'Kfz. (Linienbus) für behinderte Menschen, Schüler, Kindergartenträger'
        case RegistrationDtoUsageTypeEnum.D:
            return 'Kfz. (Mietfahrzeug mit Fahrer) für behinderte Menschen, Schüler, Kindergartenträger'
        case RegistrationDtoUsageTypeEnum.E:
            return 'Selbstfahrermietfahrzeug (Verwendung als Krankenwagen)'
        case RegistrationDtoUsageTypeEnum.F:
            return 'Selbstfahrermietfahrzeug (Verwendung als Mietfahrzeug mit Fahrer)'
        case RegistrationDtoUsageTypeEnum.G:
            return 'Selbstfahrermietfahrzeug (Verwendung als Taxi)'
        case RegistrationDtoUsageTypeEnum.K:
            return 'Krankenwagen'
        case RegistrationDtoUsageTypeEnum.L:
            return 'Linienbus'
        case RegistrationDtoUsageTypeEnum.M:
            return 'Mietfahrzeug (mit Fahrer)'
        case RegistrationDtoUsageTypeEnum.S:
            return 'Selbstfahrermietfahrzeug'
        case RegistrationDtoUsageTypeEnum.T:
            return 'Taxi'
        case RegistrationDtoUsageTypeEnum.X:
            return 'sonstige Verwendung'
        default:
            return type
    }
}

function registrationOfficePortalAvailabilityStatus(status: RegistrationOfficePortalAvailabilityDtoStatusEnum | undefined): string {
    if (!status) {
        return ''
    }
    switch (status) {
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FINDER_SYSTEM_NOT_AVAILABLE: return 'Der Zuständigkeitsfinder antwortet derzeit nicht.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.REGISTRATION_OFFICE_FOR_ADDRESS_NOT_AVAILABLE: return 'Zur angegebenen Anschrift konnte keine Zulassungsbehörde ermittelt werden.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_NOT_AVAILABLE: return 'Zur angegebenen Zulassungsbehörde konnte kein i-Kfz-Portal ermittelt werden.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_AVAILABLE: return 'Das i-Kfz Portal ist verfügbar.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_TEMPORARY_NOT_AVAILABLE: return 'Das i-Kfz Portal steht aktuell nicht zur Verfügung.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.STATUS_FOR_REGISTRATION_OFFICE_PORTAL_NOT_IN_DATABASE: return 'Noch kein Portalstatus für diese PLZ bekannt.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.ERROR: return 'Es ist ein Fehler aufgetreten'
    }
}

function organizationRoleName(role: SetUserOrganizationGroupEnum | UserOrganizationGroupDtoOrganizationRoleEnum) {
    switch (role) {
        case SetUserOrganizationGroupEnum.ADMIN:
            return 'Leiter'
        case SetUserOrganizationGroupEnum.USER:
            return 'Bearbeiter'
        default:
            return role
    }
}


export {
    deliveryInformationDeliveryServiceType,
    deliveryAddressGender,
    orderType,
    orderStatus,
    documentType,
    documentFileName,
    economicSectors,
    vehicleType,
    vehicleUsageType,
    registrationOfficePortalAvailabilityStatus,
    organizationRoleName
}
